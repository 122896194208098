import React, {useState} from 'react';
import Modal from 'react-modal';
import './Privacy.css';
import PrivacyStatement from './privacyStatement';


function Privacy() {
    const [privacyDisplay, setPrivacyDisplay] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);

    function closeModal() {
        setPrivacyDisplay(false);
    }
    function privacyModal() {
        setPrivacyDisplay(true);
    }

    function accept() {
        setIsAccepted(true);
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          height: '50%',
          width: '80%',
          padding: 0,
          boxSizing: 'border-box',
          overflow: 'hidden'
        },
        overlay: {
            zIndex: 500,
            backgroundColor: 'rgba(51,51,51,.4)'
        }
      };

    return (
        <React.Fragment>
            {!isAccepted &&
                <div className="privacy-wrapper">
                    <div className="privacy-content">
                        <h4 className="bold">Protecting Your Privacy</h4>
                        <p>More information about how PAI collects, stores, and handles personal data is available in its <span onClick={privacyModal}><i className="fas fa-external-link-alt"></i> Privacy Policy</span>.</p>
                    </div>
                    <div className="privacy-button">
                        <button onClick={accept}>Accept</button>
                    </div>
                </div>
            }
            <Modal
            isOpen={privacyDisplay}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={true}
            >
            <div className="policy-modal-close">
                <button onClick={closeModal}>&times;</button>
            </div>
            <PrivacyStatement />
        </Modal>
    </React.Fragment>
    )
}

export default Privacy;