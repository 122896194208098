import React from 'react';
import { Link } from 'react-scroll';

function Anim({close}) {
    return (
        <div className="services-details">
            <Link to="Services" spy={true} hashSpy={true} isDynamic={true} offset={-60} type="button" className="services-details-close" onClick={close}><i className="fas fa-chevron-up"></i></Link>
            <h3 className="bold">Animations</h3>
            <p>Performance Associates develops state-of-the-art graphics, voice-over audio narratives, and technically engineered, situational variables to provide life-like scenarios and improve the overall performance of your mine and plant industrial training programs.</p>
            <p>Performance Associates' animations allow for the illustration of equipment in operation, showing cutaway schematics and details of operation that cannot be observed while operating the actual equipment. These animations are particularly useful in our introductory, industrial training modules, introducing trainees to complex plant equipment and circuits in an easy-to-understand format. When critical areas require skillful training at its best, nothing matches the effectiveness of a premier, first-class animation.</p>
        </div>
    )
}

export default Anim;