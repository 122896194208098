import React from 'react';

const slides = [{
    id: 0,
    image: '/images/webp/plant_industrial_training2.webp',
    text: 
        <div className="jumbo">
            <h1>Mine and Plant Industrial Training</h1>
            <h2>Training made a difference. Ensure productivity, safety, and recovery.</h2>
            <button>Learn More &gt;</button>
        </div>
},
{
    id: 1,
    image: '/images/webp/slide_2_o2.webp',
    text: 
        <div className="jumbo">
            <h1>Customized Operations Training</h1>
            <h2>Improve the overall performance of your plant operations.</h2>
            <button>Learn More &gt;</button>
        </div>
},
{
    id: 2,
    image: '/images/webp/slide_3.webp',
    text: 
        <div className="jumbo">
            <h1>Customized Maintenance Training</h1>
            <h2>Maintenance training can improve knowledge of process and equipment, help prevent mistreatment of equipment, and ensure consistency in maintenance procedures.</h2>
            <button>Learn More &gt;</button>
        </div>
}];

export default slides;