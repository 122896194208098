import React from "react";
import "./Card.css";

function Card(props) {
    const { image, name, title, className, children } = props;
    return (
        <div className={className || "officer-card p-3"}>
            {image && (
                <div className="officer-card-image">
                    <img src={image} alt={name} />
                </div>
            )}
            <div className="officer-card-name">
                <h3 className="bold">{name}</h3>
                <h4 className="bold">{title}</h4>
            </div>
            <div className="officer-card-body">
                <div className="officer-card-detail">{children}</div>
            </div>
        </div>
    );
}

export default Card;
