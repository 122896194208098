import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="footer sticky-bottom">
            <div>&copy;2024 Perfomance Associates International, Inc.</div>
            <div className="footer-icon-group">
                <a href="https://www.facebook.com/perfnet/" target="_blank" rel="noopener noreferrer"><i className="footer-icon fab fa-facebook"></i></a>
                <a href="https://www.linkedin.com/company/performance-associates-international-inc./" target="_blank" rel="noopener noreferrer"><i className="footer-icon fab fa-linkedin"></i></a>
            </div>
            <div>Created By Joshua Anderson</div>

        </div>
    )
}

export default Footer;