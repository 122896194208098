import React from 'react';

function PrivacyStatement() {
    return (
        <div className="privacy-statement">
            <h3 className="bold">Privacy Policy</h3>
            <p><strong>Last updated:</strong> January 1, 2021</p>
            <p>This Performance Associates International, Inc. (PAI) Privacy Policy regulates the collection, use, and disclosure of information collected on the website and application located at perfnet.com. By using this website and/or application, the user of the website or application located at perfnet.com (“you”) agree to the terms of this Privacy Policy. You always have the choice not to disclose information to PAI (“us” or “we”), but keep in mind some information may be needed to register with us and to take advantage of certain functionality. If you do not agree to these terms, you are prohibited from using this website and/or application and should exit immediately. You acknowledge that the Privacy Policy is subject to modification and that your continued use of the site constitutes agreement with the then-current terms of the Privacy Policy. The date of the most recent update can be found at the top of the document.</p>
            <p>PAI will not use or share your information with anyone except as described in this Privacy Policy.</p>
            <h4 className="bold">Information Collection and Use</h4>
            <p>PAI collects information to better serve our customers and visitors to perfnet.com. When we have a better understanding of how people are using the PAI website and application, we can improve the experience of our users.</p>
            <p>The information we gather depends on the area involved and the portion of the website/application being visited. For password protected areas, we capture information including the username and the date, time, and/or length of the visit. In other areas, we record information that you affirmatively provide to us.</p>
            <p>For many areas of our application, accounts are provisioned under an agreement that PAI has established with the entity providing your employment or training services (such an agreement is termed “Customer Agreement”). Some of this information may be provided to and accessed by the entity to which your account is provisioned. Your use of the application is conditioned on the Customer Agreement, and you may lose access to the application at any time should the Customer Agreement terminate. Further, you understand that the entity may access any information that we collect from or about you through the application.</p>
            <p>If you want to be able to receive information from PAI, you may provide your name and contact information to us through the website. By doing so, you consent to receive communications from PAI.</p>
            <p>We will not sell or rent your information to other marketers. Your personal information will only be disclosed to a third party (other than as described herein) upon a merger or reorganization between us and such a third party or as needed to provide technical support or development for the website or application, or as required to process subscription payment transactions.</p>
            <h4 className="bold">Cookies and Log Data</h4>
            <p>For general visitors to our websites, we use “cookies” and other tools to collect information about users' visits, such as which pages of the site are visited and for how long. A cookie is a small amount of data, which often includes an anonymous unique identifier, that is sent to your browser from a website's server and stored on your computer's hard drive.</p>
            <p>Cookies are required to use all PAI services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you will not be able to use portions of our service and website.</p>
            <p>In addition, we may use third party services such as Google Analytics that collect, monitor, and analyze this type of information in order to increase our service's functionality. We take responsibility in validating controls and compliance of any and all third party subprocessors of this information.</p>
            <h4 className="bold">Disclosure and Legal Compliance</h4>
            <p>We will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our service.</p>
            <h4 className="bold">Links to Other Sites</h4>
            <p>Our websites will occasionally have links to other websites that are not operated by PAI. We are not responsible for the content of those websites and have no control over what information they may or may not collect from you.</p>
            <h4 className="bold">Data Storage</h4>
            <p>PAI uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run PAI services. Although PAI owns the code, databases, and all rights to the PAI suite of applications, you retain all rights to your data.</p>
            <h4 className="bold">Data Transfer</h4>
            <p>We may transfer your personal information to our service providers and others located in the United States or elsewhere in the world for the purposes described in this Privacy Policy. Different countries have different privacy laws and requirements, and some, including the United States, provide less legal protection for your personal information than others. Please know, however, that no matter where your personal information is collected, used, transferred, or stored if it was collected through our websites, it will be governed by and used in accordance with the terms of this Privacy Policy.</p>
            <h4 className="bold">Children's Privacy</h4>
            <p>We do not knowingly collect any personal information from visitors under the age of 13. If you are a parent and you discover that your child under the age of 13 provided personal information to us, please let us know and we will delete that information from our files.</p>
            <h4 className="bold">Personal Information</h4>
            <p>Through your account, you may access, and, in some cases, edit personal information collected by the application. We will make reasonable efforts to provide you with access or correct such information if it is inaccurate or delete such information if we are not required to retain it by law or for legitimate business purposes. Please note that our Customer Agreement may require that we preserve certain information in your account unchanged. We may decline to process requests that are unreasonably repetitive, require disproportionate technical effort, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law.</p>
            <h4 className="bold">Your California Privacy Rights</h4>
            <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit the <a href="https://appliedtraining.com/ccpa-privacy-notice" target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link-alt"></i> CCPA Privacy Notice</a>.</p>
            <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Information to third parties for such third parties' direct marketing purposes. In order to submit such a request, please contact us at <a href="mailto:performance@perfnet.com?subject=Privacy%20Policy%20Questions">performance@perfnet.com</a>.</p>
            <h4 className="bold">Third-Party Products and Services</h4>
            <p>You have the ability to connect third-party products and services (such as Google) to some of our applications. When you use third-party products and services, their terms and privacy policies apply to your use of those products or services. This Privacy Policy does not apply to information collected by such third parties, and we encourage you to learn more about their privacy practices before using their products or services.</p>
            <h4 className="bold">Changes to This Privacy Policy</h4>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <h4 className="bold">Questions</h4>
            <p>If you wish to view, update, or delete personally identifiable information that PAI has stored about you, or you have any questions about this Privacy Policy please contact us at <a href="mailto:performance@perfnet.com?subject=Privacy%20Policy%20Questions">performance@perfnet.com</a>.</p>
        </div>
    )
} 

export default PrivacyStatement;