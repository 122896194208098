import React from 'react';

const serviceList = [
    {
        id: 0,
        title: 'Operations Training',
        image: <i className="far fa-chart-bar fa-3x"></i>,
        copy: <p>Customized operator training programs using process descriptions with flowsheets, safe job procedures, process variables and control loops, interlocks and alarms, operating procedures, and troubleshooting guides.</p>
    },
    {
        id: 1,
        title: 'Maintenance Training',
        image: <i className="fas fa-tools fa-3x"></i>,
        copy: <p>Customized maintenance training programs with exploded graphics, detailed procedures, and management systems to help maintenance personnel safely and efficiently maintain equipment and troubleshoot problems.</p>
    },
    {
        id: 2,
        title: 'Mobile Equipment Training',
        image: <i className="fas fa-truck fa-3x"></i>,
        copy: <p>Mobile equipment operator training programs with procedures focusing on operator controls and indicators, operator safety, performance optimization, equipment operation, emergencies, and troubleshooting problems.</p>
    },
    {
        id: 3,
        title: 'Animations',
        image: <i className="fas fa-tv fa-3x"></i>,
        copy: <p>State-of-the-art graphics, animations with voice-over narratives, and technically engineered situational variables that realistically illustrate equipment operation, process flows, interlocks, alarms, and more.</p>
    }
]

export {serviceList};