import React, {useState, useEffect, useRef} from 'react';
import { useTransition, animated, config } from 'react-spring'
import './Panel.css';
import slides from '../../utils/slides/slides';

function Panel(props) {
    const {image, overlay, bgColor, children, animation, grad, offset} = props;
    const [index, set] = useState(0);
    const [imageOffset, setImageOffset] = useState(0);
    const timer = 10000;
    let bgImgRef = useRef();
    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.slow
      });
      useEffect(() => void setInterval(() => set(state => (state + 1) % 3), timer), []);
      useEffect(() => {
          setImageOffset(offset);
      }, [offset]);

    const component = 
        animation ? 
            <div className="panel" style={{backgroundColor: bgColor}}>
                <div className={overlay && "panel-image-content"}>
                    {
                        transitions.map(({ item, props, key }) => (
                            <animated.div
                                key={key}
                                className={overlay && "panel-image-overlay"}
                                style={{...props, backgroundImage: `url(${item.image})`}} />
                        ))
                    }
                    {children}
                </div>
            </div>:
            image && overlay ? 
                <div className="panel" style={!grad ? {backgroundColor: bgColor} : {backgroundImage: grad}} ref={bgImgRef}>
                    <div className={overlay && "panel-image-content"}>
                        <div className={overlay && "panel-image-overlay"} style={!grad ? {backgroundImage: `url(${image})`, backgroundPositionY: `${imageOffset}px`}:{backgroundImage: `url(${image})`, opacity: .4, backgroundPositionY: `${imageOffset}px`}}></div>
                        {children}
                    </div>
                </div> :
                image && !overlay ?
                    <div className="panel-no-overlay" style={image && {backgroundImage: `url(${image})`, backgroundPositionY: `${imageOffset}px`}} ref={bgImgRef}>
                        {children}
                    </div>:
                    <div className="panel" style={{backgroundColor: bgColor}}>
                        {children}
                    </div>;
    return (
        component
    )
}

Panel.defaultProps = {
    bgColor: 'black',
    overlay: true,
    animation: false,
    grad: false,
    offset: 0
}

export default Panel;