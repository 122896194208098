import React, {useState, useEffect} from 'react';
import {Element, Link} from 'react-scroll';
import './Jumbotron.css';
import Panel from '../../common/panel/Panel';
import ContactUs from '../../utils/contactUs/ContactUs';


function Jumbotron({quickOpen}) {
    const [slideInt, setSlideInt] = useState(0);
    const timer = 10000;

    const slides = [{
        text: 
            <div className="jumbo col-12 col-lg-7 p-5 mt-5 mt-lg-0">
                <h1>Mine and Plant Industrial Training</h1>
                <h3>Training made a difference. Ensure productivity, safety, and recovery.</h3>
                <Link to="Services" spy={true} hashSpy={true} isDynamic={true} offset={-60}>Learn More</Link>
            </div>
    },
    {
        text: 
            <div className="jumbo col-12 col-lg-7 p-5 mt-5 mt-lg-0">
                <h1>Customized Operations Training</h1>
                <h3>Improve the overall performance of your plant operations.</h3>
                <Link to="Row1" spy={true} hashSpy={true} isDynamic={true} offset={-60} onClick={() => quickOpen({row: 1, content: 0})}>Learn More</Link>
            </div>
    },
    {
        text: 
            <div className="jumbo col-12 col-lg-7 p-5 mt-5 mt-lg-0">
                <h1>Customized Maintenance Training</h1>
                <h3>Maintenance training can improve knowledge of process and equipment, help prevent mistreatment of equipment, and ensure consistency in maintenance procedures.</h3>
                <Link to="Row1" spy={true} hashSpy={true} isDynamic={true} offset={-60} onClick={() => quickOpen({row: 1, content: 1})}>Learn More</Link>
            </div>
    }];

    useEffect(() => void setInterval(() => setSlideInt(state => (state + 1) % 3), timer), [])
    return (
        <Element name="Home" className="element">
            <Panel animation={true} timer={timer}>
                <div className="jumbo-wrap row m-0 py-3 px-5 p-lg-5 p-0">
                    <div className="order-last order-lg-first col-12 col-md-8 col-lg-5 col-xl-4 m-0 my-5 m-lg-0 pt-lg-5">
                        <ContactUs id={"top"} shadow={true} />
                    </div>
                    {slides[slideInt].text}
                </div>
            </Panel>
        </Element>
    )
}

export default Jumbotron;