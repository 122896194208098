import React from 'react';
import './Card.css';

function DepartmentCard({department, current, setCurrent}) {

    return (
        <div className="department-card-wrapper col-12 col-md-6 col-lg-3 px-1">
            <div className="department-card-content">
                <div className="department-card-icon py-2">
                    {department.icon}
                </div>
                <div className="department-card-title">
                    {department.name}
                </div>
                <div className={`department-card-expand ${(department !== current) && "department-card-hidden"} `}>
                    {department.description}
                </div>
                <div className="department-card-button">
                    <button className="department-expand-button" onClick={() => department === current ? setCurrent(false) : setCurrent(department)}>{department === current ? "Read Less" : "Read More"}</button>
                </div>
            </div>
        </div>
    );
}

export default DepartmentCard;