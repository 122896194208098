import React from 'react';
import { Link } from 'react-scroll';
import './Card.css';

function ServiceCard(props) {
    const {image, title, copy, onClick} = props;
    return (
        <div className="service-card col-12 col-lg-3">
            <div className="service-card-content p-2">
                <div className="service-card-image py-3">
                    {image}
                </div>
                <div className="service-card-name pb-2">
                    <h3>{title}</h3>
                </div>
                <div className="service-card-body">
                    <div className="service-card-detail">
                        {copy}
                        {onClick ? <Link to="Row1" spy={true} hashSpy={true} isDynamic={true} offset={-60} className="service-card-link py-3 px-2" onClick={onClick}>Learn More</Link> : <button className="no-card-link py-3 px-2">Coming Soon</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;