import React from 'react';
import { Link } from 'react-scroll';

function Maint({close}) {
    return (
        <div className="services-details">
            <Link to="Services" spy={true} hashSpy={true} isDynamic={true} offset={-60} type="button" className="services-details-close" onClick={close}><i className="fas fa-chevron-up"></i></Link>
            <h3 className="bold">Maintenance</h3>
            <p>Performance Associates develops maintenance training programs, using exploded graphics, explanatory text, detailed procedures, and management systems, if necessary, to improve the overall performance of your maintenance operations.</p>
            <p>Performance Associates' maintenance training programs are customized to provide necessary knowledge to both maintenance tradesmen and supervisors. Our maintenance training includes tradesman training on the specific maintenance requirements of plant equipment and instruments, as well as supervisor training on the associated maintenance management systems and supervisory skills. We assist clients in establishing and improving maintenance management performance through the development and implementation of maintenance planning systems, preventive maintenance procedures, spare parts inventory management methods, and cost control systems. Exploded graphics, explanatory text, animations, and detailed procedures help to ensure that maintenance personnel know how to optimize equipment availability and reliability.</p>

            <p>Performance Associates' maintenance training package can also include an on-site survey to evaluate your maintenance systems and the effectiveness of those systems. We submit a comprehensive report to the client, outlining our findings and recommendations. Performance Associates can then assist in implementing any recommendations. Such an implementation program normally consists of improved systems, maintenance skills, and management training. Performance Associates has more than 30 years of experience in providing and assisting clients with their maintenance training programs.</p>
            <p>Performance Associates' computer-based maintenance training programs provide your people with the knowledge to:</p>
            <ul>
                <li>Safely and efficiently maintain mine and plant equipment.</li>
                <li>Optimize control of critical process variables.</li>
                <li>Effectively troubleshoot equipment problems.</li>
                <li>Effectively plan and schedule maintenance work.</li>
                <li>Optimize equipment availability and reliability.</li>
                <li>Maximize the return on your capital investment.</li>
            </ul>
        </div>
    )
}

export default Maint;