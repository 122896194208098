import React, {useState} from 'react';
import './App.css';
import Navigation from './Nav/Nav_v2';
import Jumbotron from './content/Jumbotron/Jumbotron';
import ServicesV2 from './content/Services/ServicesV2';
import Contact from './content/Contact/Contact';
import Footer from './Footer/Footer';
import Experience from './content/Experience/Experience';
import PeopleV2 from './content/People/PeopleV2';
import News from './content/News/News';
import Privacy from './utils/privacy/Privacy';
import WhoWeAre from './content/WhoWeAre/WhoWeAre';

function App() {
  const [quickOpen, setQuickOpen] = useState(false);
    return (
      <div className="App">
        <Navigation />
        <Jumbotron quickOpen={setQuickOpen} />
        <ServicesV2 quickOpen={quickOpen} setQuickOpen={setQuickOpen} />
        <WhoWeAre />
        <Experience />
        <PeopleV2 />
        <News />
        <Contact />
        <Privacy />
        <Footer />
      </div>
  );
}

export default App;
