import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './Industry.css';
import industryList from '../../../utils/industry/industry';

function Industry() {
    const [industryVis, setIndustryVis] = useState(false);
    const industryCheckVisibility = (isVisible) => {
        !industryVis && setIndustryVis(isVisible);
    }
    const industryItems = industryList.map((i, index) => {
        return (
            <div key={"Industry" + index.toString()} className="industry-icon-group">
                <img src={i.image} alt={i.name + " icon"} />
                <p>{i.name}</p>
            </div>
        )
    });

    return (
        <VisibilitySensor onChange={industryCheckVisibility} partialVisibility={'top'} minTopValue={100}>
            <div className="industry-wrapper row m-0">
                <div className="industry-content col-12 col-lg-6">
                    <div className={`industry-wrapper-text-content ${!industryVis && "hidden-left"}`}>
                    {/* <div className={`industry-wrapper-text-content hidden-left`}> */}
                        <h3 className="bold">Industries</h3>
                        <p>Performance Associates specializes in the natural resource industries including mining, metallurgical and nonmetallic minerals processing, forest products, waste water handling, and power generation.</p>
                    </div>
                </div>
                <div className="icons-group col-12 col-lg-6">
                    {industryItems}
                </div>
            </div>
        </VisibilitySensor>
    );
}

export default Industry;