import React from 'react';
import { Link } from 'react-scroll';
import './Services.css';

function PlantOps({close}) {
    return (
        <div className="services-details">
            <Link to="Services" spy={true} hashSpy={true} isDynamic={true} offset={-60} type="button" className="services-details-close" onClick={close}><i className="fas fa-chevron-up"></i></Link>
            <h3 className="bold">Operations</h3>
            <p>Performance Associates develops plant operator training programs, using process descriptions and flowsheets, safe job procedures, process variable and control loop descriptions and diagrams, interlock and alarm documentation, operating procedures, and troubleshooting guides to improve the overall performance of your plant operations.</p>
            <p>Performance Associates' plant operator training programs emphasize the specific knowledge necessary to operate a plant. Our three-tier concept of effective plant operator training starts with fundamental knowledge, but includes plant-specific knowledge that is then reinforced through the use of detailed animations and interactive simulations. The plant operator training materials include process descriptions and flowsheets, safe job procedures, process variable and control loop descriptions and diagrams, interlock and alarm documentation, operating procedures, and troubleshooting guides. Our plant operator training programs teach classroom theory as well as in-plant practice. Performance Associates will make sure that your operators become plant operations experts. Performance Associates has more than 30 years of experience in providing and assisting clients with their plant operator training programs.</p>
            <p>Performance Associates' computer-based plant operator training programs provide your people with the knowledge to:</p>
            <ul>
                <li>Operate the process effectively and safely.</li>
                <li>Optimize control of critical process variables.</li>
                <li>Consistently operate the plant in accordance with company policies and procedures.</li>
                <li>Minimize equipment damage.</li>
                <li>Maximize production rates during new plant start-up.</li>
                <li>Effectively respond to process upsets and process alarms.</li>
                <li>Maximize the return on your capital investment.</li>
            </ul>
            <p>"Great stuff. I am impressed. It's just what we needed."</p>
            <p>-- Hal Roach, process plant operations coordinator, Newcrest Mining Limited, regarding an animation of the new Lihir plant autoclave circuit.</p>
        </div>
    )
}

export default PlantOps;