import React from 'react';
import { Link } from 'react-scroll';

function MobileEq({close}) {
    return (
        <div className="services-details">
            <Link to="Services" spy={true} hashSpy={true} isDynamic={true} offset={-60} type="button" className="services-details-close" onClick={close}><i className="fas fa-chevron-up"></i></Link>
            <h3 className="bold">Mobile Equipment</h3>
            <p>Performance Associates develops mobile equipment operator training programs, focusing on operator controls and indicators; safety; and performance optimization, operating, emergency, and troubleshooting procedures to improve the overall performance of your mobile equipment operations.</p>
            <p>Performance Associates' mobile equipment operator training programs emphasize the knowledge to help increase safety awareness, improve operating effectiveness, decrease accidents, and reduce mobile equipment operation and maintenance costs. Our interactive mobile equipment operator training programs include operator controls and indicators; safety; and performance optimization, operating, emergency, and troubleshooting procedures that are specific to the equipment. Mobile equipment represents a significant operating cost in mining operations and is also a major factor in many mine and plant accidents. Mobile equipment operator training designed specific to your equipment will help to ensure optimization of your mobile equipment operations. Performance Associates has more than 30 years of experience in providing and assisting clients with their mobile equipment operator training programs.</p>
            <p>Performance Associates' computer-based mobile equipment operator training programs provide your people with the knowledge to:</p>
            <ul>
                <li>Utilize equipment safety placards and procedures.</li>
                <li>Implement mine operating and safety rules.</li>
                <li>Understand function of equipment components, as well as any associated troubleshooting procedures.</li>
                <li>Correctly use equipment controls.</li>
                <li>Operate machinery in accordance with normal and emergency operating procedures.</li>
                <li>Optimize performance, including minimization of cycle times.</li>
            </ul>
        </div>
    )
}

export default MobileEq;