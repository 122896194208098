import React from 'react';

const carouselList = [
    {
        id: 1,
        name: 'First Quantum Minerals Ltd.',
        description: [
            {
                header: 'Cobre Panama—Panama',
                body: <p>Performance Associates is developing a comprehensive operations and maintenance computer-based training program for First Quantum Mineral's large open-pit Cobre Panama copper development project in Panama. Also being provided are introductory training modules on unit operations, process control, basic mechanical skills, mining, and mobile equipment, and a training unit on supervisory skills development. PAI is also developing maintenance training modules for the large pieces of equipment in the mining operation. All materials will be provided in both English and Spanish.</p>
            },
            {
                header: 'Sentinel—Zambia',
                body: <p>Performance Associates is working with staff at First Quantum Mineral's Sentinel property in Zambia as they conduct introductory module training and application of the PAI <i>Field Tracker</i> devices that are being used to track operations and maintenance field data.</p>
            }
        ],
        image: '/projects/logo_first_quantum.png'
    },
    {
        id: 2,
        name: 'Teck',
        description: [
            {
                header: 'Highland Valley Copper—Canada',
                body: <p>Highland Valley Copper and Performance Associates have a long history of working together to keep Highland Valley Copper's PAI-developed plant and mobile equipment computer-based training program current and ready to train new hires. Performance Associates continues to support Highland Valley Copper's efforts on an ongoing basis.</p>
            },
            {
                header: 'Red Dog—Alaska, USA',
                body: <p>Performance Associates is developing training modules for all areas of the Red Dog lead/zinc process plant operated by Teck in northwestern Alaska. These modules are being incorporated into the existing on-site computer-based training system installed by Performance Associates. PAI continues to support the installation and operation of our proprietary tablet-based software, <i>Field Tracker</i>, used to track operations and maintenance field activities.</p>
            }
        ],
        image: '/projects/teck_logo.gif'
    },
    {
        id: 3,
        name: 'Excellon Resources Inc.',
        description: [
            {
                header: 'Excellon—Mexico',
                body: <p>Performance Associates provided a supervisory skills development program in English and Spanish to Excellon Resources for use in its La Platosa Mine in Durango, Mexico. Training for Excellon personnel was conducted in Mexico and at the PAI office in Tucson.</p>
            }
        ],
        image: '/projects/excellon-logo.png'
    },
    {
        id: 4,
        name: 'Nordgold',
        description: [
            {
                header: 'Nordgold—Russia',
                body: <p>Performance Associates is working with Nordgold in their effort to define a skills assessment program.</p>
            }
        ],
        image: '/projects/nordgold_logo.png'
    },
    {
        id: 5,
        name: 'Yamana Gold',
        description: [
            {
                header: 'Yamana Gold—Argentina',
                body: <p>For Yamana's new Cerro Moro gold project in Argentina, Performance Associates is developing a plant-specific English/Spanish plant operations and maintenance computer-based training program covering the eight areas of the plant. PAI is also providing introductory computer-based training modules and a supervisor skills development program.</p>
            }
        ],
        image: '/projects/yamana_logo.png'
    },
    {
        id: 6,
        name: 'Kinross Gold',
        description: [
            {
                header: 'Kinross Gold—Mauritania',
                body: <p>As Kinross Gold optimizes elements of its processing operations at the Tasiast gold mine in Mauritania, Performance Associates is updating the corresponding parts of the English/French plant operations computer-based training program first developed by Performance Associates in 2011.</p>
            }
        ],
        image: '/projects/kinross_logo.jpg'
    },
    {
        id: 7,
        name: 'Anagold Madencilik Sanayi Ve Ticaret A.Ş.',
        description: [
            {
                header: 'Anagold Madencilik Sanayi Ve Ticaret A.Ş.—Turkey',
                body: <p>Performance Associates is preparing an English/Turkish computer-based training program for Anagold's Çöpler Sulfide Plant in Turkey. The training program consists of introductory training on unit operations and process control and 14 new plant operations manuals, including on-site training at the Çöpler operation in Turkey. Performance Associates developed an operations and maintenance training program for the Çöpler Heap Leach Project in 2009.</p>
            }
        ],
        image: '/projects/anagold_logo.png'
    },
    {
        id: 8,
        name: 'Kaz Minerals',
        description: [
            {
                header: 'Kaz Minerals—Kazakhstan',
                body: <p>In 2013, Performance Associates developed and delivered a plant operations computer-based training program in English and Russian for the 15 areas of the KAZ Minerals sulfide concentrator and Bozshakol clay plant. PAI also provided introductory training modules on unit operations and process control, as well as our learning management system, <i>PTracker Pro</i>. Performance Associates is currently providing continuing support for the <i>PTracker Pro</i> trainee tracking system.</p>
            }
        ],
        image: '/projects/kaz-minerals.png'
    },
    {
        id: 9,
        name: 'Torex Gold',
        description: [
            {
                header: 'Torex Gold—Mexico',
                body: <p>Performance Associates recently developed a comprehensive operations and maintenance computer-based training program for this gold plant in Mexico, conducted pre-start-up training, and assisted in commissioning the plant. Since start-up, Torex has consistently met or exceeded production goals.<br /><br />Performance Associates continues to provide maintenance and updates for the <i>PTracker Pro</i> trainee tracking system still in use at the plant.</p>
            }
        ],
        image: '/projects/torexgold.jpg'
    },
    {
        id: 10,
        name: 'NextMed',
        description: [
            {
                header: 'NextMed—Tucson, Arizona, USA',
                body: <p>NextMed provides mobile lithotripsy, surgical laser, and endoscopy services and technology to patients, physicians, and other health care providers. Performance Associates migrated NextMed's extracorporeal shock wave lithotripsy (ESWL) training program and laser technologist training program into a computer-based format and continues to manage a cloud-based training program for NextMed's ESWL technologists.</p>
            }
        ],
        image: '/projects/nextmed.jpg'
    },
    {
        id: 11,
        name: 'Roy O. Martin',
        description: [
            {
                header: 'Roy O. Martin—Texas and Louisiana, USA',
                body: <p>Performance Associates is developing a computer-based training program for Roy O. Martin's new OSB plant being constructed in Corrigan, Texas. Introductory modules, as well as plant-specific modules, are being developed. PAI's <i>PTracker Pro</i> learning management system is also being installed. As part of the project, previously developed PAI training manuals for the Oakdale plant are being converted to an interactive, computer-based training program. Performance Associates is also conducting training on-site in Corrigan.</p>
            }
        ],
        image: '/projects/roy_o_logo.jpg'
    },
    {
        id: 12,
        name: 'Northern Star Resources, Ltd.',
        description: [
            {
                header: 'Northern Star Resources, Ltd.—Kalgoorlie, Australia',
                body: <p>Performance Associates is providing introductory training modules on unit operations, process control, and basic mechanical skills hosted as a cloud-based subscription application for the Kanowna Belle operation near Kalgoorlie in Western Australia. PAI is also developing equipment-specific maintenance training modules for the Kanowna Belle operation.</p>
            }
        ],
        image: '/projects/nsr_logo.jpg'
    },
    {
        id: 13,
        name: 'Centerra Gold',
        description: [
            {
                header: 'Centerra Gold—Toronto and Ontario, Canada',
                body: <p>Performance Associates updated the previously developed computer-based training program to operate in the most recent version of the <i>PTracker Pro</i> trainee tracking system.</p>
            }
        ],
        image: '/projects/centerragold_logo.png'
    },
    {
        id: 14,
        name: 'Acacia Mining Operations',
        description: [
            {
                header: 'Acacia Mining Operations—Turkey',
                body: <p>Performance Associates is preparing an English/Turkish computer-based training program for the Acacia Mining Operations copper sulfide process plant in Turkey. The training program consists of introductory training modules on unit operations, process control, and basic mechanical skills hosted as a cloud-based subscription application with training tracked using PAI's learning management system, <i>PTracker Pro</i>. PAI is also developing plant-specific computer-based training modules for two key areas of the process plant.</p>
            }
        ],
        image: '/projects/acacia_logo.png'
    },
    {
        id: 15,
        name: 'Goldcorp',
        description: [
            {
                header: 'Goldcorp—Mexico',
                body: <p>Performance Associates is preparing an English/Spanish computer-based training program for Goldcorp's pyrite leach project at Peñasquito in Mexico. The training program consists of introductory training modules on unit operations, process control, and basic mechanical skills hosted as a cloud-based subscription application with training tracked using Performance Associates' learning management system, <i>PTracker Pro</i>. PAI is also developing eight plant-specific computer-based training modules for the pyrite leach circuit in the process plant.</p>
            }
        ],
        image: '/projects/goldcorp_penasquito.jpg'
    },
    {
        id: 16,
        name: 'American Zinc Recycling',
        description: [
            {
                header: 'American Zinc Recycling—Mooresboro, North Carolina, USA',
                body: <p>Performance Associates is preparing a computer-based training program for the American Zinc Products facility in Mooresboro, North Carolina. The training program consists of introductory training modules on unit operations, process control, and basic mechanical skills hosted as a cloud-based subscription application with training tracked using Performance Associates' learning management system, <i>PTracker Pro</i>. PAI is also developing six plant-specific computer-based training modules for the Mooresboro process plant.</p>
            }
        ],
        image: '/projects/american_zinc.png'
    }
]

export default carouselList;