import React from 'react'
import { Element } from 'react-scroll';
import Carousel from './Carousel/Carousel';
import Location from './Location/Location';
import './Experience.css'
import Panel from '../../common/panel/Panel';
import Industry from './Industry/Industry';
import Clients from './Clients/Clients';

function Experience() {
    return (
        <Element name="Experience" className="element">
            <Panel image="/images/webp/experience_bg_83.webp" overlay={false} bgColor="rgba(0, 0, 0, 0)">
                <h2 className="experience-header pt-3 pt-lg-5">Experience</h2>
                <Industry />
                <Clients />
                <Location />
                <Carousel />
            </Panel>
        </Element>
    );
}

export default Experience;