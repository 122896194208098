import React from 'react';

const departments = [
    {
        name: <h4 className="bold">Editing Department</h4>,
        description: <p>The editing department is responsible for making sure the high quality of work that has been associated with Performance Associates International, Inc. (PAI), for close to 40 years is upheld. The editing department works with all other departments to ensure consistency in the written material that PAI produces. In particular, editors and engineers work together so that PAI’s training materials are not only technically correct but also easy to understand. The primary goal of the editing department is to create a product that is as close to perfect as we can make it.</p>,
        icon: <i className="fas fa-edit fa-4x"></i>
    },
    {
        name: <h4 className="bold">Engineering—Operations Department</h4>,
        description: <p>The engineers at Performance Associates International, Inc. (PAI), use plant-specific engineering and vendor documentation to develop well-rounded operator training courses. The training courses first ensure understanding of the overall plant material flows, then provide detailed information such as process descriptions and flowsheets, process variables and corresponding control loops, interlocks associated with equipment, alarms and the appropriate response, and operating procedures. PAI engineers create these courses and provide in-person, on-site operator training to build operator confidence in fundamentals, principles of operation of equipment, and an overall understanding of the plant to optimize operations.</p>,
        icon: <i className="fas fa-hard-hat fa-4x"></i>
    },
    {
        name: <h4 className="bold">Maintenance Department</h4>,
        description: 
            <div className="department-content-group">
                <p>Performance Associates International, Inc. (PAI), maintenance specialists are mechanical and electrical shop floor technicians and engineers with many years of experience. The PAI maintenance training department offers a range of training, from basic mechanical and electrical fundamentals training to specific maintenance procedures. Basic mechanical and electrical fundamentals are the foundation of the mechanical maintenance training program. Training with these modules helps to ensure that mechanical maintenance personnel have the necessary basic craft skills before they train with the more advanced equipment-specific modules. Equipment-specific maintenance procedures describe in detail how to remove, disassemble, test, repair, and reassemble a piece of equipment.</p>
                <p>PAI knows that our clients desire to maintain their equipment and keep it operating at top performance. Maintenance training can improve knowledge of process and equipment, help prevent mistreatment of equipment, and ensure consistency in maintenance procedures.</p>
            </div>,
        icon: <i className="fas fa-wrench fa-4x"></i>
    },
    {
        name: <h4 className="bold">Multimedia Department</h4>,
        description: <p>The multimedia department at Performance Associates International, Inc. (PAI), designs and creates e-learning courses that combine text with sounds, pictures, graphics, video clips, and digital animation. Multimedia specialists create animated flowsheets, animations, and 2D and 3D graphics to visually depict and reinforce what trainees learn about the complex concepts and systems in PAI’s written material. Once the written material is edited and approved, it is developed into a web-based format using animated flowsheets, animations, and graphics. After the development process, the courses receive a quality assurance check to ensure proper functionality. Our goal is to provide an engaging, responsive, and functional learning experience for our clients by providing e-learning courses that reflect the latest multimedia technology.</p>,
        icon: <i className="fas fa-photo-video fa-4x"></i>
    },
]

export default departments;