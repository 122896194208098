import React, { useState } from 'react';
import DepartmentCard from '../../../common/card/DepartmentCard';
import departments from './departments';
import './TeamBar.css';

function TeamBar() {
    const [activeDepartment, setActiveDepartment] = useState(false);

    const depts = departments.map((dept, i) => <DepartmentCard key={`dept${i}`} department={dept} current={activeDepartment} setCurrent={setActiveDepartment} />)
    return (
        <div className="team-bar-wrapper py-4">
            <div className="team-bar-header">
                <h3>Meet the Departments</h3>
            </div>
            <div className="team-bar">
                <div className="row p-0 m-0 justify-content-center">
                    {depts.slice(0,4)}
                </div>
                <div className="row p-0 m-0">
                    {depts.slice(4)}
                </div>
            </div>
        </div>
    )
}

export default TeamBar;