import React from 'react';
import { Link } from 'react-scroll';
import './Card.css';

function ArticleCard({pdf, onClick, loadedPdf}) {
    return (
        <div className={`article-card ${loadedPdf === pdf && "active-article"} col-6 col-md-2`}>
            <Link className="article-link" to="News & Updates" spy={true} hashSpy={true} isDynamic={true} offset={-60} onClick={onClick}>
                <div className={pdf.isTall ? "article-card-image-tall" : "article-card-image"}>
                    {pdf.image && <img src={pdf.image} alt={pdf.name} />}
                </div>
                <div className="article-card-content">
                    {pdf.name}
                    <p>{pdf.description.length > 125 ? pdf.description.slice(0, 125) + "..." : pdf.description}</p>
                </div>
            </Link>
        </div>
    )
}

export default ArticleCard;