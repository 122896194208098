import React, {useRef} from 'react';
import './Expand.css';

function Expand({children, isOpen, className, size, ...props}) {
    const expandRef = useRef();

    return (
        <div className={"expand-collapse " + className} ref={expandRef} style={isOpen ? (expandRef.current.scrollHeight > size.current.scrollHeight) ? {maxHeight: expandRef.current.scrollHeight} : {maxHeight: size.current.scrollHeight + 4000} : null}>
            {children}
        </div>
    )
}

Expand.defaultProps = {
    size: {
        current: {
            scrollHeight: 1
        }
    }
}

export default Expand;