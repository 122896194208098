import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './Clients.css';

function Clients() {
    const [clientVis, setClientVis] = useState(false);
    const clientCheckVisibility = (isVisible) => {
        !clientVis && setClientVis(isVisible);
    }
    return (
        <VisibilitySensor onChange={clientCheckVisibility} partialVisibility={'top'} minTopValue={100}>
            <div className="client-wrapper row flex-wrap-reverse m-0">
                <div className="client-image col-12 col-lg-6">
                    <img src="/industry_logo/clients_image.jpg" alt="Collage of clients' logos Performance Associates have developed content for." />
                </div>
                <div className="client-content col-12 col-lg-6 rever">
                    <div className={`client-wrapper-text-content ${!clientVis && "hidden-right"}`}>
                        <h3 className="bold">Clients</h3>
                        <p>Performance Associates specializes in the natural resource industries including mining, metallurgical and nonmetallic minerals processing, forest products, waste water handling, and power generation.</p>
                    </div>
                </div>
            </div>
        </VisibilitySensor>
    );
}

export default Clients;