const industryList = [
    {
        image: '/industry_logo/aluminum.png',
        name: 'Aluminum/ Alumina'
    },
    {
        image: '/industry_logo/cement.png',
        name: 'Cement'
    },
    {
        image: '/industry_logo/coal.png',
        name: 'Coal'
    },
    {
        image: '/industry_logo/cobalt.png',
        name: 'Cobalt'
    },
    {
        image: '/industry_logo/copper.png',
        name: 'Copper'
    },
    {
        image: '/industry_logo/diamond.png',
        name: 'Diamonds'
    },
    {
        image: '/industry_logo/forest.png',
        name: 'Forest Products'
    },
    {
        image: '/industry_logo/gold.png',
        name: 'Gold'
    },
    {
        image: '/industry_logo/gas.png',
        name: 'Industrial Gases'
    },
    {
        image: '/industry_logo/mineral.png',
        name: 'Industrial Minerals'
    },
    {
        image: '/industry_logo/iron.png',
        name: 'Iron and Steel'
    },
    {
        image: '/industry_logo/lead.png',
        name: 'Lead'
    },
    {
        image: '/industry_logo/molybdenum.png',
        name: 'Molybdenum'
    },
    {
        image: '/industry_logo/nickel.png',
        name: 'Nickel'
    },
    {
        image: '/industry_logo/oil.png',
        name: 'Oil Sands'
    },
    {
        image: '/industry_logo/chemical.png',
        name: 'Other Chemical Processes'
    },
    {
        image: '/industry_logo/platinum.png',
        name: 'Platinum and Palladium'
    },
    {
        image: '/industry_logo/power.png',
        name: 'Power Generation'
    },
    {
        image: '/industry_logo/radioactive.png',
        name: 'Radioactive and Toxic Waste Treatment'
    },
    {
        image: '/industry_logo/silver.png',
        name: 'Silver'
    },
    {
        image: '/industry_logo/water.png',
        name: 'Water Treatment'
    },
    {
        image: '/industry_logo/zinc.png',
        name: 'Zinc'
    }
];

export default industryList;