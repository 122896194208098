import React, {useState, useEffect, useRef} from 'react';
import './Media.css';
import MediaVideo from './MediaVideo/MediaVideo';
import videoDb from './videoList';

function Media() {
    const [loadedVideo, setLoadedVideo] = useState(false);
    const scroller = useRef();
    const generateVideoes = videoDb.map((video, index) => <MediaVideo key={"Video" + index} id={index} video={video} onClick={setLoadedVideo} currentVideo={loadedVideo} />)

    useEffect(() => {
        if (!loadedVideo) {
            setLoadedVideo(videoDb[0]);
        }
    }, [loadedVideo]);

    function scroll(dir) {
        scroller.current.scrollTo({ 
            left: dir ? scroller.current.scrollLeft - 300 : scroller.current.scrollLeft + 300,
            behavior: 'smooth'
        });
    }

    return (
        <div className="media-wrapper">
            <h3>PAI in Action</h3>
            <div className="media-content">
                <div className="media-selected-content row m-0 p-0 px-lg-5">
                    <div className="media-video-player col-12 col-lg-8 px-3 py-2 pt-lg-0">
                        <iframe title={loadedVideo.description} src={`https://www.youtube.com/embed/${loadedVideo && loadedVideo.video}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="media-video-description col-10 col-lg-4 py-3 pt-lg-0">
                        {loadedVideo.description}
                    </div>
                </div>
                <div className="media-video-selector" ref={scroller}>
                    <div className="video-scroller-prev" onClick={() => scroll(true)}>&lt;</div>
                        {generateVideoes}
                    <div className="video-scroller-next" onClick={() => scroll(false)}>&gt;</div>
                </div>
            </div>
        </div>
    )
}

export default Media;