import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './Team.css';

function Team() {
    const [cultureVis, setCultureVis] = useState(false);
    const cultureCheckVisibility = (isVisible) => {
        !cultureVis && setCultureVis(isVisible);
    }

    return (
        <VisibilitySensor onChange={cultureCheckVisibility} partialVisibility={'top'} minTopValue={50}>
            <div className="team-wrapper">
                <div className="team-content-wrapper">
                    <div className="team-content row m-0 p-0">
                        <div className={`team-culture-box ${!cultureVis && "hidden-teams-top"} my-2 my-lg-0 col-12 col-lg-4`}>
                            <h3>Our Culture</h3>
                            <p>Since 1983, our training programs have consistently produced outstanding results for hundreds of clients on nearly every continent of the world, in both English and native languages. If your operations involve people and equipment, we can provide training. Let us help evaluate your needs and suggest a training program specifically tailored to the results you want; after all, it is what we do.</p>
                        </div>
                        <div className={`team-where-box ${!cultureVis && "hidden-teams-bottom"} my-2 my-lg-0 col-12 col-lg-4`}>
                            <h3>Where We've Been</h3>
                            <p>Since its founding in 1983, Performance Associates International, Inc. (PAI), has been privileged to help mines and plants across the globe train their operators and maintenance personnel. PAI has built and maintained a lasting reputation for high-quality content and excellent service.</p>
                            <p>For close to 40 years, PAI has provided customized training for mines and plants in 48 countries on 6 continents. Whether you require in-depth, customized training content or off-the-shelf introductory modules, PAI can help improve your process plant training program.</p>
                        </div>
                        <div className={`team-location-box ${!cultureVis && "hidden-teams-top"} my-2 my-lg-0 col-12 col-lg-4`}>
                            <h3>Where We Work</h3>
                            <p>Performance Associates is housed in Oro Valley, Arizona, just three miles north of Tucson proper, set between the Catalina and Tortolita mountain ranges. Oro Valley is a sunny, moderately sized city that has been home to PAI since 1994.</p>
                        </div>
                    </div>
                    <div className="team-image row">
                        <img className="col-8 pt-3" src="/images/webp/pai_fun_facts.webp" alt="Fun Facts" />
                    </div>
                </div>
            </div>
        </VisibilitySensor>
    )
}

export default Team;