import React, { useState, useRef } from 'react';
import { Element } from 'react-scroll';
import ArticleCard from '../../common/card/ArticleCard';
import Panel from '../../common/panel/Panel';
import './News.css';
import whitePagesList from '../../utils/whitePages/whitePages';
import Media from '../Media/Media';

function News() {
    const [loadedPdf, setLoadedPdf] = useState(false);
    const scroller = useRef();
    const articles = whitePagesList.map((i, index) => <ArticleCard key={'article-card-' + index} pdf={i} loadedPdf={loadedPdf} onClick={() => setLoadedPdf(i)} />)
    return (
        <Element name="News & Updates" className="element">
            <Panel bgColor='white' overlay={true}>
                <div className="news-wrapper pt-3">
                    <h2>News &amp; Updates</h2>
                    <div className="news-content">
                    <iframe src={loadedPdf.file} title={loadedPdf.name} frameBorder="0" className={`article-frame ${!loadedPdf && "hidden-article"}`}></iframe>
                        <div className="news-scroller row" ref={scroller}>
                            {articles}
                        </div>
                    </div>
                </div>
                <Media />
            </Panel>
        </Element>
    )
}

export default News;