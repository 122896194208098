import React from 'react';
import './MediaVideo.css';

function MediaVideo({id, video, onClick, currentVideo}) {
    return(
        <div className={`media-video ${(video === currentVideo) && "active-video"}`} onClick={() => onClick(video)}>
            <div className="media-video-content">
                <img className="py-2" src={video.tn} alt={video.title} />
                <p className="bold">{video.title}</p>
            </div>
        </div>
    );
}

export default MediaVideo;