import React from 'react';
import Panel from '../../common/panel/Panel';
import './People.css';
import { Element } from 'react-scroll';
import Team from './Team/Team';
import TeamBar from './TeamBar/TeamBar';
import Officer from './Officer/Officer';

function PeopleV2() {
    return (
        <Element name="Our Team" className="element">
            <Panel image="/images/webp/coalminingworker.webp" overlay={true} bgColor={"#00a7e0"} grad={"radial-gradient(farthest-corner at 80% 20%, rgba(0, 167, 224, .1) 25%, rgba(0, 167, 224, 1) 50%"}>
                <div className="people-header pt-3 pt-lg-5">
                    <h2>Our Team</h2>
                </div>
                <Officer />
                <TeamBar />
                <Team />
            </Panel>
        </Element>
    )
}

export default PeopleV2;