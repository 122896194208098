import React, { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
import { Element } from "react-scroll"; // Added this import statement
import Panel from "../../common/panel/Panel";
import ServiceCard from "../../common/card/ServiceCard";
import "./Services.css";
import { serviceList as i } from "../../utils/services/services";
import PlantOps from "./PlantOps";
import Maint from "./Maint";
import MobileEq from "./MobileEq";
import Anim from "./Anim";

function ServicesV2({ quickOpen, setQuickOpen }) {
    const [row1, setRow1] = useState(false);
    const [row1Content, setRow1Content] = useState(false);

    function closeWindow() {
        setRow1(false);
    }

    function openContent(selectedRow, selectedContent) {
        if (selectedRow === 1) {
            setRow1(true);
            setRow1Content(selectedContent);
        }
    }

    useEffect(() => {
        if (quickOpen) {
            openContent(quickOpen.row, quickOpen.content);
            setQuickOpen(false);
        }
    }, [quickOpen, setQuickOpen]);

    return (
        <Element name="Services" className="element">
            <Panel bgColor={"#f5f5f5"} overlay={false}>
                <div className="services-wrapper">
                    <h2 className="mt-3 mt-lg-5">Services</h2>
                    <div className="services-card-group">
                        <div className="services-card-group-row-1 row d-flex mx-0 my-3 px-3 px-lg-6">
                            <ServiceCard
                                image={i[0].image}
                                title={i[0].title}
                                copy={i[0].copy}
                                onClick={() => openContent(1, 0)}
                            />
                            <ServiceCard
                                image={i[1].image}
                                title={i[1].title}
                                copy={i[1].copy}
                                onClick={() => openContent(1, 1)}
                            />
                            <ServiceCard
                                image={i[2].image}
                                title={i[2].title}
                                copy={i[2].copy}
                                onClick={() => openContent(1, 2)}
                            />
                            <ServiceCard
                                image={i[3].image}
                                title={i[3].title}
                                copy={i[3].copy}
                                onClick={() => openContent(1, 3)}
                            />
                        </div>
                        <Element name="Row1">
                            <Transition
                                in={row1}
                                timeout={600}
                                unmountOnExit
                                on
                                onExited={() => setRow1Content(false)}
                            >
                                {(state) => {
                                    return (
                                        <div
                                            className={
                                                state === "exiting"
                                                    ? "services-details-container-expanded-exiting"
                                                    : row1
                                                    ? "services-details-container-expanded"
                                                    : null
                                            }
                                        >
                                            {row1Content === 0 && <PlantOps close={closeWindow} />}
                                            {row1Content === 1 && <Maint close={closeWindow} />}
                                            {row1Content === 2 && <MobileEq close={closeWindow} />}
                                            {row1Content === 3 && <Anim close={closeWindow} />}
                                        </div>
                                    );
                                }}
                            </Transition>
                        </Element>
                    </div>
                </div>
            </Panel>
        </Element>
    );
}

export default ServicesV2;
