import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Panel from '../../common/panel/Panel';
import {Element} from 'react-scroll';
import './WhoWeAre.css';

function WhoWeAre() {
    const [isVis, setIsVisible] = useState(false);
    const checkVisibility = (isVisible) => {
        !isVis && setIsVisible(isVisible);
    }

    return (
        <Element name="About" className="element">
            <Panel bgColor="white" overlay={false}>
                <VisibilitySensor onChange={checkVisibility} partialVisibility={'top'} minTopValue={100}>
                    <div className='who-we-are pb-3 pb-lg-5'>
                        <h2 className="mt-3 mt-lg-5">Who We Are</h2>
                        <div className="who-we-are-image">
                            <img src={"/images/webp/symonsconecrusher.webp"} alt="Symons Cone Crusher" className={"who-we-are-img " + (!isVis && "not-entered-img")} />
                        </div>
                        <div className="who-we-are-content row m-0 p-0 d-flex">
                            <div className={"d-flex flex-column col-12 col-md-6 who-we-are-col-2 " + (!isVis && "not-entered-col-2")}>
                                <p>Performance Associates International (PAI) is the worldwide leader in mine and plant industrial training. Our interactive web-based training and experienced instructors will improve your company's operations and overall performance.</p>
                                <p>For more than 30 years, Performance Associates has been a pioneer in mine and plant industrial training, developing and delivering first-class computer-based training (CBT) programs. Performance Associates' training programs are customized and designed for both mine and plant operations. We provide mine and plant industrial training to improve performance in current operations and ensure success during start-up of new operations.</p>
                                <p>Our start-up training programs have been instrumental in safely starting up new operations and achieving full production in record time at companies throughout the world. We specialize in the training of mine and plant operators, maintenance personnel, and supervisors, providing the specific skills necessary to effectively operate, maintain, and start up mine and plant operations. Our worldwide, multicultural experience allows us to be effective with trainees at any training level.</p>
                            </div>
                            <div className={"d-flex flex-column col-12 col-md-6 who-we-are-col-3 " + (!isVis && "not-entered-col-3")}>
                                <p>Performance Associates' plant industrial training focuses on and follows our concept of the three tiers of effective plant operator training: basic introductory training on the fundamentals of unit operations; intermediate training on plant-specific processes, equipment, and operating procedures; and, finally, advanced training with the use of high-end animations and simulations. Our plant maintenance training focuses on safety, equipment, troubleshooting, and preventive maintenance.</p>
                                <p>Performance Associates' mine industrial training programs include the fundamentals of mobile equipment operations and preventive maintenance, focusing on mobile equipment operator training specific to the client location and emphasizing knowledge of safety, operator controls and indicators, operating and emergency procedures, and performance optimization.</p>
                                <p>Since 1983, Performance Associates' mine and plant industrial training programs have consistently produced outstanding results for hundreds of clients on nearly every continent of the world. If your company's operations involve heavy industrial process circuits and equipment, Performance Associates can develop and conduct a training program, providing materials in both English and local languages, tailored to your desired results.</p>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
            </Panel>
        </Element>
    )
}

export default WhoWeAre;