import React from 'react';
import {Element} from 'react-scroll';
import './Contact.css';
import Panel from '../../common/panel/Panel';
import ContactUs from '../../utils/contactUs/ContactUs';

function Contact() {
    return (
        <Element name="Contact" className="element">
            <Panel bgColor="#333">
                <div className="contact row m-0 p-0 py-lg-3">
                    <div className="col-12 col-lg-6">
                        <iframe
                            title="Performance Associates International, Inc. Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3369.012914475339!2d-110.9666246845095!3d32.392015510822525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d60cc98b705fe9%3A0xb4192dba1c7e64af!2sPerformance%20Associates%20International!5e0!3m2!1sen!2sus!4v1585116004578!5m2!1sen!2sus"
                            className="contact-map p-3 h-100" />
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                        <ContactUs id={"bottom"} shadow={false} />
                    </div>
                </div>
            </Panel>
        </Element>
    )
}

export default Contact;