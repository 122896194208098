import React, { useState } from 'react';
import './CarouselItem.css';
import Expand from '../../common/expand/Expand';

function CarouselItem(props) {
    const [detailed, setDetailed] = useState(false);
    const {job, emitter} = props;
    const description = job.description.map((desc, index) => {
        return <div key={"cidesc" + index.toString()} className="job-item-description-group">
                    <h4>{desc.header}</h4>
                    {desc.body}
                </div>
    });
    const toggleDetailed = () => {
        setDetailed(!detailed);
        emitter();
    }
    return (
        <div className="job-item-wrapper col-12 col-md-6 col-lg-3 d-flex p-2 p-md-3">
            <div className="job-item d-flex flex-column p-3 w-100">
                <div className="job-item-image">
                    <img src={job.image} alt={job.name} />
                </div>
                <div>
                    <div className="job-item-name pt-2">
                        <h3>{job.name}</h3>
                    </div>
                        <Expand isOpen={detailed}>
                            {description}
                        </Expand>
                    <div className="job-item-button-group">
                        <button
                            type="button"
                            className="job-item-button"
                            onClick={toggleDetailed}>
                                {detailed ? 'Read Less' : 'Read More'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarouselItem;